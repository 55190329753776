
const gitInfo = {
  branch: "master",
  commit: "c256e6b",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/c256e6bc0fa30eb8ccab7e3c8a8cd63a14fd19e4",
  date: "12/12/2024",
};

export default gitInfo;
  